import { createContext, ReactNode, useContext, useMemo } from 'react';
import { Book, request, useAsync, ThemeProvider, useThemeConfig, createGlobalStyle } from '@topwrite/common';
import useFile from './use-file';
import Color from 'color';

export const CssContext = createContext<string | undefined>(undefined);

interface Props {
    readonly children: ReactNode;
}

export function CssProvider({ children }: Props) {
    const file = useFile();
    const themeConfig = useThemeConfig();

    const { result, loading } = useAsync(async () => {
        const { data } = await request(file.relative(Book.style));

        return data;
    }, []);

    const theme = useMemo(() => {
        return {
            primaryColor: new Color(themeConfig.primaryColor),
        };
    }, [themeConfig]);

    if (loading) {
        return null;
    }

    return <CssContext.Provider value={result}>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </ThemeProvider>
    </CssContext.Provider>;
}

const GlobalStyle = createGlobalStyle`
  :root {
    --ttw-primary-color: ${props => props.theme.primaryColor.toString()};
  }
`;

export default function useCss() {
    return useContext(CssContext);
}
