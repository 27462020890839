import useArticle from '../lib/use-article';
import { useBook } from '@topwrite/common';
import { PropsWithChildren, useEffect } from 'react';
import { history } from '../lib/history';
import useFile from '../lib/use-file';

export default function ToFirstArticle({ children }: PropsWithChildren<any>) {
    const { summary } = useBook();
    const article = useArticle();
    const file = useFile();
    useEffect(() => {
        if (!article) {
            const first = summary.getFirstArticle();
            if (first) {
                history.replace(file.relative(first.path));
            }
        }
    }, [article]);

    if (!article) {
        return null;
    }
    return children;
}
