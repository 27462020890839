import { useIntl, useSelector } from '@topwrite/common';

interface PoweredByProps {
    className?: string;
}

export default function PoweredBy({ className }: PoweredByProps) {

    const intl = useIntl();

    const { poweredBy } = useSelector('options');

    if (poweredBy) {
        return <div className={className}>
            {intl.formatMessage(
                { defaultMessage: `由 {link} 强力驱动` },
                {
                    link: <a href={poweredBy.link} target='_blank'>
                        {poweredBy.name}
                    </a>
                }
            )}
        </div>;
    }
    return null;
}
