import { createBrowserHistory, createMemoryHistory, History as H, Listener, To, createPath } from 'history';

const SUPPORTED = (
    typeof window !== 'undefined' &&
    window.history &&
    !navigator.userAgent.match(/((iPod|iPhone|iPad).+\bOS\s+[1-4]\D|WebApps\/.+CFNetwork)/) &&
    window.location.protocol !== 'file:'
);

export class History {

    history: H;

    constructor() {
        this.history = SUPPORTED ? createBrowserHistory() : createMemoryHistory({
            initialEntries: [window.location]
        });
    }

    get location() {
        const location = this.history.location;

        return {
            ...location,
            toString() {
                return createPath(location);
            }
        };
    }

    listen(listener: Listener) {
        return this.history.listen(listener);
    }

    replace(location: To) {
        return this.history.replace(location);
    }

    push(location: To) {
        return this.history.push(location);
    }
}

export let history: History;

export function createHistory() {
    return history = new History();
}
