import { Markdown as CommonMarkdown } from '@topwrite/common';
import { ComponentProps, useMemo } from 'react';
import Img from './html/img';
import Attachment from './html/attachment';
import Video from './html/video';
import Audio from './html/audio';
import A from './html/a';
import useCss from '../lib/use-css';

type MarkdownProps = ComponentProps<typeof CommonMarkdown>

export default function Markdown({ children, components, ...props }: MarkdownProps) {

    const css = useCss();

    return <CommonMarkdown {...props} css={css} components={useMemo(() => {
        return {
            a: A,
            img: Img,
            attachment: Attachment,
            video: Video,
            audio: Audio,
            ...components
        };
    }, [components])}>
        {children}
    </CommonMarkdown>;
}
