import { css, InjectedComponent, InjectedComponentSet, styled } from '@topwrite/common';
import Markdown from './markdown';
import { ComponentProps, createElement, HeadingHTMLAttributes, useEffect } from 'react';
import useArticle from '../lib/use-article';
import useFile from '../lib/use-file';
import { history } from '../lib/history';

function Heading({ depth, id, children, ...props }: HeadingHTMLAttributes<HTMLHeadingElement>) {

    if (id) {
        children = <>
            <Anchor tabIndex={-1} id={id} />
            {children}
        </>;
    }

    return createElement(`h${depth}`, props, children);
}

const components = {
    heading: Heading
};

interface ArticleProps extends Omit<ComponentProps<typeof Markdown>, 'children' | 'as'> {
    className?: string;
    offset?: number;
}

const jumpToAnchor = (hash: string) => {
    if (hash) {
        const anchor = document.getElementById(decodeURIComponent(hash).substr(1));
        if (anchor) {
            window.scrollTo(0, anchor.offsetTop);
        }
    }
};

export default function Article({ className, header, footer, offset, aside = true, ...props }: ArticleProps) {

    const file = useFile();
    const article = useArticle();

    useEffect(() => {
        jumpToAnchor(history.location.hash);

        const unlisten = history.listen(function(e) {
            jumpToAnchor(e.location.hash);
        });

        return () => {
            unlisten();
        };
    }, [file.path]);

    if (header === undefined && article) {
        header = <Title>{article.title}</Title>;
    }

    header = <>
        <InjectedComponentSet role={'page:article:before'} />
        {header}
        <InjectedComponentSet role={'page:article:content:before'} />
    </>;

    footer = <>
        <InjectedComponentSet role={'page:article:content:after'} />
        {footer}
        <InjectedComponentSet role={'page:article:after'} />
    </>;

    if (aside) {
        aside = {
            header: <>
                <InjectedComponentSet role={'page:article:aside:before'} />
                {typeof aside === 'object' && aside.header}
            </>,
            footer: <>
                {typeof aside === 'object' && aside.footer}
                <InjectedComponentSet role={'page:article:aside:after'} />
            </>
        };
    }

    const children = <InjectedComponent
        role={'page:article:markdown'}
        component={Markdown}
        props={{
            header,
            footer,
            aside,
            children: file.content,
            components,
            ...props
        }}
    />;

    return <Container key={file.path} className={className} offset={offset}>
        {children}
    </Container>;
}

const Anchor = styled.a`
  display: block;
  position: relative;
  top: -.5em;
`;

const Container = styled.div<{ offset?: number }>`
  ${props => props.offset && css`
    ${Anchor} {
      top: calc(-.5em - ${props.offset}px);
    }
  `}
`;


const Title = styled.h1`
  border-bottom: 1px solid #eee;
  font-size: 2em;
  line-height: 1.2;
  padding: 0 0 .5em;
  margin: 0 0 1em;
  font-weight: 400;
`;
