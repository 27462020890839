import { useEffect, useState } from 'react';

const desktopThresholdWidth = 996;

type WindowSize = 'desktop' | 'mobile'

export const windowSizes: Record<string, WindowSize> = {
    desktop: 'desktop',
    mobile: 'mobile',
};

export default function useWindowSize() {
    function getSize(): WindowSize {
        return window.innerWidth > desktopThresholdWidth
            ? windowSizes.desktop
            : windowSizes.mobile;
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
