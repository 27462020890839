import { useSelector } from '@topwrite/common';

//废弃的  改用useAsset
export default function useAssetUrl(filename: string) {
    const { lfs, file } = useSelector('page');

    if (lfs) {
        const { url, files } = lfs;
        const oid = files[filename];
        if (oid) {
            return url.replace('{oid}', oid);
        }
    }
    return file.relative(filename);
}
