export const logHello = () => {
    console.log(
        `%cWelcome to TopWrite!%c
`,
        `padding-top: 0.5em; font-size: 2em;`,
        'padding-bottom: 0.5em;',
    );

    const pkg = require('../package.json');

    console.log(`[ua:version] ${navigator.userAgent}`);
    console.log(`[client:version] v${pkg.version}`);
};
