import { InjectedComponent, isUrl, useSelector } from '@topwrite/common';

interface Props {
    element: 't-attachment' | 'img' | 'video' | 'audio';
    src?: string;
}

export default function Asset({ element, src, ...props }: Props) {
    const { lfs, file } = useSelector('page');

    if (src && lfs && !isUrl(src) && file) {
        const { url, files } = lfs;
        const filename = file.resolve(decodeURIComponent(src));
        const oid = files[filename];
        if (oid) {
            src = url.replace('{oid}', oid);
        }
    }

    return <InjectedComponent
        role={'page:asset'}
        props={{
            src,
            ...props
        }}
        component={element}
    />;
}
