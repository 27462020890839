import { useBook, useIntl } from '@topwrite/common';

export default function useTitle() {

    const intl = useIntl();
    const { config } = useBook();

    return config.getValue('title', intl.formatMessage({
        defaultMessage: '未命名文档'
    }));
}
