import { useThrottle, useEvent } from '@topwrite/common';
import { useState } from 'react';

const win = typeof window === 'undefined' ? null : window;
const getScrollY = (): number =>
    (win as Window).scrollY !== void 0
        ? (win as Window).scrollY
        : (win as Window).pageYOffset === void 0
            ? 0
            : (win as Window).pageYOffset;

export const useWindowScroll = (fps = 30): number => {

    const [scrollTop, setScrollTop] = useState(() => getScrollY());

    const updateScrollTop = useThrottle(() => {
        setScrollTop(getScrollY());
    }, fps);
    useEvent(win, 'scroll', updateScrollTop);
    return scrollTop;
};

export default useWindowScroll;
