import { useEffect } from 'react';

export default function useLockBodyScroll(lock: boolean = true, reset: boolean = true): void {
    useEffect(() => {
        if (lock) {
            document.body.style.overflow = 'hidden';
            if (reset) {
                window.scrollTo(0, 0);
            }
            return () => {
                document.body.style.overflow = 'visible';
            };
        }
    }, [lock]);
}
