import path from 'path';
import { useBook } from '@topwrite/common';
import Link from './link';

interface Props {
    title: string;
    url: string;
    className?: string;
}

export default function NavLink({ url, title, className }: Props) {
    const { summary } = useBook();

    if (!url.startsWith('http') && path.extname(url) === '.md') {
        const linkArticle = summary.getArticle(article => article.ref === url);
        if (linkArticle) {
            return <Link className={className} to={linkArticle}>{title}</Link>;
        }
    }

    return <a className={className} href={url} target='_blank'>{title}</a>;
}
