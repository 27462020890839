import { Model } from '@topwrite/common';

export type AskHandler = (keyword: string, part?: string) => Promise<ReadableStreamDefaultReader> | ReadableStreamDefaultReader

interface AskState {
    enable: boolean;
    handler?: AskHandler;
    status: 'idle' | 'loading' | 'stalled' | 'error';
    query: string;
    part?: string;
    answer: string;
}

class Ask extends Model<AskState> {

    initialState: AskState = {
        enable: false,
        status: 'idle',
        query: '',
        answer: '',
    };

    async *fetch(query: string) {
        query = query.trim();

        if (query) {
            const { handler, part } = yield *this.getState('ask');

            if (handler) {
                try {
                    yield this.setState(state => {
                        state.query = query;
                        state.status = 'loading';
                        state.answer = '';
                    });

                    const reader = await handler(query, part);

                    yield this.setState(state => {
                        state.status = 'stalled';
                    });

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            break;
                        }
                        yield this.setState(state => {
                            state.answer += value;
                        });
                    }

                    yield this.setState(state => {
                        state.status = 'idle';
                    });
                } catch (e) {
                    yield this.setState(state => {
                        state.answer = '';
                        state.status = 'error';
                    });
                }
            }
        }
    }

    *setPart(part?: string) {
        yield this.setState((state) => {
            if (part !== state.part) {
                state.query = '';
                state.answer = '';
                state.status = 'idle';
                state.part = part;
            }
        });
    }

    *setHandler(handler?: AskHandler) {
        yield this.setState((state) => {
            state.handler = handler;
            state.enable = !!handler;
        });
    }

}

export const ask = new Ask();
