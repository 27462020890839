import { styled } from '@topwrite/common';

export default function PageLoader() {

    return <Container>
        <Spinner />
    </Container>;
}

const Spinner = styled.div`
  @keyframes spinner-border {
    to {
      transform: rotate(360deg)
    }
  }

  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid #3c60ff;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
