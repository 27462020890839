import { InjectedComponent, useBook, Helmet } from '@topwrite/common';
import useTitle from '../lib/use-title';
import React, { useEffect, useMemo } from 'react';
import { ArticleContext } from '../lib/use-article';
import useFile from '../lib/use-file';
import useLogo from '../lib/use-logo';
import { history } from '../lib/history';
import { createPath } from 'history';

const Menu = React.lazy(() => import('./menu'));

export default function Page() {
    const { summary } = useBook();
    const title = useTitle();
    const file = useFile();

    const article = useMemo(() => {
        return summary.getArticle(article => article.getPath() === file.path);
    }, [summary, file]);

    useEffect(() => {
        if (window.parent !== window) {
            window.addEventListener('message', function(event) {
                try {
                    const [type, action, ...args] = event.data;
                    if (type === 'history') {
                        switch (action) {
                            case 'replace':
                                history.replace(args[0]);
                                break;
                            case 'push':
                                history.push(args[0]);
                                break;
                        }
                    }
                } catch {

                }
            });

            history.listen(function(e) {
                const url = `${window.location.origin}${createPath(e.location)}`;
                window.parent.postMessage(['history', e.action.toLowerCase(), url], '*');
            });

            window.parent.postMessage(['bootstrapped'], '*');
        }

        window.dispatchEvent(new Event('bootstrapped'));
    }, []);

    const logo = useLogo();

    return <>
        <Helmet>
            <title>{`${article ? `${article.title} - ` : ''}${title}`}</title>
            <link rel='shortcut icon' href={logo} type='image/png' />
        </Helmet>
        <ArticleContext.Provider value={article}>
            {/* todo 废弃的app */}
            <InjectedComponent role='app' />

            <InjectedComponent role='page' />
            <React.Suspense fallback={null}>
                <InjectedComponent role='page:menu' component={Menu} />
            </React.Suspense>
        </ArticleContext.Provider>
    </>;
}
