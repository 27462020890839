import { File } from '@topwrite/common';
import { immerable } from 'immer';

File.prototype[immerable] = true;

declare module '@topwrite/common' {

    export interface File {
        [immerable]: boolean
    }

}
