import { AnchorHTMLAttributes } from 'react';
import { isUrl, SummaryArticle, useBook } from '@topwrite/common';
import path from 'path';
import Link from './link';

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    base?: SummaryArticle;
}

const Anchor = ({ base, href, ...props }: AnchorProps) => {

    const { summary } = useBook();

    if (href) {
        if (isUrl(href)) {
            props.target = '_blank';
        } else {
            if (path.extname(href) === '.md') {
                let ref;
                if (base) {
                    ref = decodeURIComponent(path.join(path.dirname(base.ref), href));
                } else {
                    ref = decodeURIComponent(href);
                }

                const article = summary.getArticle(article => article.ref === ref);
                if (article) {
                    return <Link to={article} {...props} />;
                }
            }
            href = '#!';
        }
    }
    return <a href={href} {...props} rel='noopener noreferrer' />;
};

export default Anchor;
