import { lazy, Suspense } from 'react';
import type { DocSearchProps } from './doc-search';

const DocSearch = lazy(() => import('./doc-search'));

export default function Search(props: DocSearchProps) {
    return <Suspense fallback={null}>
        <DocSearch {...props} />
    </Suspense>;
}
