import { ActionsType, File, getPayload, Model, request, RequestConfig, RequestInstance } from '@topwrite/common';
import { history } from '../lib/history';

interface PageState {
    loading: boolean;
    error?: Error;
    request: RequestInstance;
    config: RequestConfig;
    file: File;
    lfs?: {
        url: string
        files: {
            [index: string]: string
        }
    };
}

type Interceptors = RequestInstance['interceptors']

class Page extends Model<PageState> {

    initialState = {
        loading: false,
        request: request.create(),
        config: {},
    };

    subscription({ fetch }: ActionsType<Page>) {
        let prevLocation = history.location;
        history.listen(({ location }) => {
            if (prevLocation.pathname !== location.pathname) {
                fetch(location.pathname);
                requestAnimationFrame(() => {
                    window.scrollTo(0, 0);
                });
            }
            prevLocation = location;
        });
    }

    async *fetch(pathname: string) {

        yield this.setState(state => {
            state.loading = true;
        });

        const book = yield *this.getState('book');

        const { request, config } = yield *this.getState('page');


        try {
            let payload;

            const url = decodeURI(pathname);

            const fetchPayload = async () => {
                const { data } = await request.get(url, config);

                return typeof data === 'string' ? getPayload(data) : data;
            };

            if (book.sha && 'caches' in window) {
                const cache = await caches.open(`book-${book.id}-${book.sha}`);
                const cacheResponse = await cache.match(url);

                if (cacheResponse) {
                    payload = await cacheResponse.json();
                }

                if (!payload) {
                    payload = await fetchPayload();
                    await cache.put(url, new Response(JSON.stringify(payload), {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }));
                }
            } else {
                payload = await fetchPayload();
            }

            const { file, lfs } = payload;

            yield this.setState(state => {
                state.file = new File(file);
                state.lfs = lfs;
            });
        } catch (e: any) {
            yield this.setState(state => {
                state.error = e;
            });
        } finally {
            yield this.setState(state => {
                state.loading = false;
            });
        }
    }

    *intercept(handler: (interceptors: Interceptors) => void) {
        const { request } = yield *this.getState('page');

        handler(request.interceptors);
    }

    *updateConfig(updater: (config: RequestConfig) => void) {
        yield this.setState(state => {
            updater(state.config);
        });
    }
}

export const page = new Page();
