import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Book } from '@topwrite/common';
import useFile from './use-file';

const LogoContext = createContext<string | undefined>(undefined);

interface Props {
    readonly children: ReactNode;
}

export function LogoProvider({ children }: Props) {

    const [logo, setLogo] = useState(() => {
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    });

    const file = useFile();

    useEffect(() => {
        const src = file.relative(Book.logo);

        const image = new Image();

        image.addEventListener('load', function() {
            const canvas = document.createElement('canvas');
            canvas.height = this.height;
            canvas.width = this.width;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(this, 0, 0);
                canvas.toBlob((blob) => {
                    if (blob) {
                        setLogo(window.URL.createObjectURL(blob));
                    }
                });
            }
        });

        image.src = src;
    }, []);

    return <LogoContext.Provider value={logo}>
        {children}
    </LogoContext.Provider>;
}

export default function useLogo() {
    return useContext(LogoContext)!;
}
