import { useSelector, isUrl } from '@topwrite/common';

export default function useAsset() {
    const { lfs, file } = useSelector('page');

    const getUrl = (filename: string) => {
        if (isUrl(filename)) {
            return filename;
        }
        if (lfs) {
            const { url, files } = lfs;
            const oid = files[filename];
            if (oid) {
                return url.replace('{oid}', oid);
            }
        }
        return file.relative(filename);
    };

    const upload = (_file: File, _onProgress?: (event: ProgressEvent) => void): Promise<string> => {
        throw new Error('upload not support in reader context');
    };

    return { getUrl, upload };
}
