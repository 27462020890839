import useLogo from '../lib/use-logo';

interface LogoProps {
    className?: string;
    size?: number;
}

export default function Logo({ size = 60, className }: LogoProps) {

    const logo = useLogo();

    return <img className={className} src={logo} width={size} height={size} alt='logo' />;
}
